import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"about"} />
      <Helmet>
        <title>O nas | Odkryj Amirelix</title>
        <meta
          name={"description"}
          content={"Gdzie rodzą się niezwykłe wydarzenia!"}
        />
        <meta property={"og:title"} content={"O nas | Odkryj Amirelix"} />
        <meta
          property={"og:description"}
          content={"Gdzie rodzą się niezwykłe wydarzenia!"}
        />
        <meta
          property={"og:image"}
          content={"https://amirelix.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://amirelix.com/img/3595030.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://amirelix.com/img/3595030.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://amirelix.com/img/3595030.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://amirelix.com/img/3595030.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://amirelix.com/img/3595030.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://amirelix.com/img/3595030.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="--color-primary"
        padding="60px 0"
        sm-padding="40px 0"
      >
        <Box
          margin="-16px -16px -16px -16px"
          padding="0px 0px 0px 0px"
          display="flex"
          width=" "
          flex-wrap="wrap"
        >
          <Box
            padding="16px 16px 16px 16px"
            display="flex"
            width="50%"
            lg-width="100%"
          >
            <Box
              padding="98px 64px 98px 64px"
              display="flex"
              flex-direction="column"
              background="--color-light"
              color="--dark"
            >
              <Text
                as="h1"
                margin="12px 0"
                font="--headline2"
                color="--grey"
                letter-spacing="1px"
                text-transform="uppercase"
              >
                Nasza historia
              </Text>
              <Text as="p" margin="12px 0" font="base">
                Amirelix - gdzie Twoje wielkie pomysły spotykają się z naszą
                pasją do planowania wydarzeń. Założeni w przekonaniu, że każda
                specjalna okazja powinna być spektakularna, wyspecjalizowaliśmy
                się w tworzeniu niezapomnianych wydarzeń, o których mówi się
                latami. Od skromnych początków do stania się latarnią
                kreatywności w planowaniu wydarzeń, nasza podróż była jedną z
                innowacji, skrupulatnego planowania i niekończącej się pasji.
              </Text>
            </Box>
          </Box>
          <Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
            <Box
              padding="16px 16px 16px 16px"
              display="flex"
              width="100%"
              lg-width="33.3333%"
              md-width="100%"
              sm-width="100%"
            >
              <Box
                padding="0px 0px 624px 0px"
                display="flex"
                flex-direction="column"
                background="url(https://amirelix.com/img/3.jpg) center/cover"
                width="100%"
                lg-padding="0px 0px 248px 0px"
                sm-padding="0px 0px 380px 0px"
              />
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--lead"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            W Amirelix oferujemy kompleksowy wachlarz usług, aby zapewnić
            bezbłędny przebieg wydarzenia. Nasze doświadczenie obejmuje
            logistykę, wybór miejsca, koordynację cateringu, rezerwację rozrywki
            i indywidualne koncepcje dekoracji. Zajmujemy się każdym aspektem z
            precyzją i troską, pozwalając ci się zrelaksować i cieszyć
            uroczystością.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://amirelix.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Dlaczego się wyróżniamy
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Innowacyjne koncepcje: Wnosimy świeże pomysły na każde wydarzenie.
            <br />
            <br />
            Konfigurowalne doświadczenia: Twoja wizja jest naszym planem. Pozwól
            nam przekształcić Twoje pomysły w rzeczywistość.
            <br />
            <br />
            Praktyki zrównoważonego rozwoju: Wierzymy w odpowiedzialne
            planowanie wydarzeń z poszanowaniem środowiska.
            <br />
            <br />
            Wyjątkowa obsługa klienta: Zadowolenie klienta jest naszym
            najwyższym priorytetem. Nasz zespół dąży do doskonałości.
          </Text>
        </Box>
      </Section>
      <Section
        padding="100px 0 120px 0"
        sm-padding="80px 0 90px 0"
        quarkly-title="Statistics-10"
      >
        <Text
          margin="0px 0px 20px 0px"
          letter-spacing="2px"
          color="#cd9f55"
          font="normal 500 20px/1.5 --fontFamily-sansVerdana"
          border-color="#dca654"
        >
          Wybierz swoje doświadczenie
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Box min-width="100px" min-height="100px" lg-width="100%">
            <Text
              margin="0 0px 0px 0px"
              font="normal 600 26px/1.2 --fontFamily-serifGeorgia"
              color="#050c32"
              sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia"
            >
              Wyobraź sobie wydarzenie idealnie dopasowane do Twoich marzeń. W
              Amirelix zapraszamy do stworzenia z nami własnej, unikalnej
              historii. Niezależnie od tego, czy świętujesz miłość, osiągnięcia,
              kamienie milowe, czy po prostu dlatego, pozwól nam dodać magiczny
              akcent, który zamieni Twoje wydarzenie w arcydzieło.
            </Text>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
